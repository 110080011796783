import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import PlayerGameFixedPoints from './PlayerGameFixedPoints';
import PlayerGameVariablePoints from './PlayerGameVariablePoints';
import { db } from './api';
import SeriesSummary from "./SeriesSummary";

function PlaySeries(props) {

  const organization = props.organization;

  const { seriesId } = useParams();
  const { gameNumberParam } = useParams();
  const [series, setSeries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  let seriesDocRef = db.collection("organizations").doc(organization.id).collection("series").doc(seriesId);

  let gameIndex = gameNumberParam ? parseInt(gameNumberParam) - 1 : null

  let game;
  let gameNumber;
  let PlayerGamePoints;
  if(!!!errorMsg && !loading) {
    if(gameIndex === null || gameIndex >= series.data().games.length || gameIndex < 0) { // prevent trying to load an out of bounds index from params
      gameIndex = series.data().currentGameIndex
    }

    game = series.data().games[gameIndex];
    gameNumber = gameIndex + 1
    PlayerGamePoints = game.pointsToWin ? PlayerGameFixedPoints : PlayerGameVariablePoints
  }

  function loadSeries() {
    seriesDocRef.onSnapshot(function(docRef) {
      if(docRef.exists) {
        setSeries(docRef);
        setLoading(false);
      } else {
        setErrorMsg("Series not found.")
      }
    });
  }

  function onClickEndGame(event) {
    event.preventDefault()
    endGame()
  }

  function endGame() {
    // fetch again to ensure we avoid a race condition from recordGoal
    seriesDocRef.get().then(function(seriesDoc) {
      let games = seriesDoc.data().games;
      let currentGameIndex = seriesDoc.data().currentGameIndex;

      let gamePlayers = games[currentGameIndex].players
      let gameTied = gamePlayers.every( (player) => player.score === gamePlayers[0].score )
      if(gameTied){
        alert('Games cannot end in a tie! Keep it going!')
        return true
      }

      let leadingPlayer = games[currentGameIndex].players.reduce(function(prev, current) {
        return (prev.score > current.score) ? prev : current
      });
      let gamePlayersIndex = games[currentGameIndex].players.map(p => p.name).indexOf(leadingPlayer.name);


      games[currentGameIndex].endedAt = Date.now();
      leadingPlayer.winner = true;
      games[currentGameIndex].winner = leadingPlayer;
      games[currentGameIndex].players[gamePlayersIndex].winner = true;

      // update series and game
      let seriesPlayers = seriesDoc.data().players;

      // update this player's series score
      let seriesPlayersIndex = seriesPlayers.map(p => p.name).indexOf(leadingPlayer.name);
      seriesPlayers[seriesPlayersIndex].score = seriesPlayers[seriesPlayersIndex].score + 1;

      // get the leading player to see if they have enough wins to end the series
      let leadingSeriesPlayer = seriesPlayers.reduce(function(prev, current) {
        return (prev.score > current.score) ? prev : current
      });
      // if the series is over
      if(leadingSeriesPlayer.score >= Math.round(seriesDoc.data().bestOf / 2)) {
        seriesPlayers[seriesPlayersIndex].winner = true;
        seriesDocRef.update({
          endedAt: Date.now(),
          winner: seriesPlayers[seriesPlayersIndex],
          players: seriesPlayers,
          games: games
        });
      } else {
        seriesDocRef.update({
          currentGameIndex: currentGameIndex + 1,
          players: seriesPlayers,
          games: games
        });
      }
    });
  }

  function recordGoal(player) {
    if(!!game.endedAt) {
      alert(`${game.winner.name} has already won the game!`);
      return false;
    }

    let games = series.data().games;
    let currentGameIndex = series.data().currentGameIndex;
    let gamePlayersIndex = games[currentGameIndex].players.map(p => p.name).indexOf(player.name);
    let newPlayerScore = games[currentGameIndex].players[gamePlayersIndex].score + 1;

    player.score = newPlayerScore; // this allows us to see the player's current score at the time each goal was scored
    let newGoal = {
      scoredAt: Date.now(),
      player: player,
    };
    // officially start the game
    // TODO this should probably be when you enter this page rather than at the first goal. The first could take a while to score!
    if(games[currentGameIndex].startedAt === null) {
      games[currentGameIndex].startedAt = newGoal.scoredAt;
    }
    games[currentGameIndex].goals.push(newGoal);
    games[currentGameIndex].players[gamePlayersIndex].score = newPlayerScore; // this is the total current game score, updated each goal

    seriesDocRef.update({
      games: games
    }).then(function () {
      // if the game is over based on achieving the set number of points to win
      if(games[currentGameIndex].pointsToWin && (games[currentGameIndex].players[gamePlayersIndex].score === games[currentGameIndex].pointsToWin)) {
        endGame()
      }
    });
  }

  function removeGoal(goal, index) {
    if(game.endedAt) {
      alert('You cannot yet remove a goal for a game that has ended.')
    } else {
      let games = series.data().games;
      let currentGameIndex = series.data().currentGameIndex;
      games[currentGameIndex].goals.splice(index, 1) // remove the goal from the goals array

      let gamePlayersIndex = games[currentGameIndex].players.map(p => p.name).indexOf(goal.player.name);
      games[currentGameIndex].players[gamePlayersIndex].score = games[currentGameIndex].players[gamePlayersIndex].score - 1 // decrement that scoring player's score
      // save it!
      seriesDocRef.update({
        games: games
      })
    }
  }

  function ordinalize(n) {
    const s=["th","st","nd","rd"],
      v=n%100;
    return n+(s[(v-20)%10]||s[v]||s[0]);
  }

  useEffect(() => {
    loadSeries();

    return function cleanup() {
      // close onSnapshot?
    };
  }, []);

  return (
    <div>
      {
        errorMsg ? <p>{errorMsg}</p> :
          loading ? <p>Loading...</p> :
            series.data().endedAt && !gameNumberParam ?
              <div>
                <SeriesSummary players={series.data().players} winner={series.data().winner} bestOf={series.data().bestOf} gameType={series.data().gameType} extended={false} />
                <section className={`game-scores`}>
                {
                  series.data().games.map(function (game, index) {
                    if(!!game.startedAt) {
                      return <h3 key={index} className={`game-score`}>
                        <Link to={`/series/${seriesId}/game/${index + 1}`} className={`game-number`}>Game {index + 1}:</Link>
                        {game.players.sort((a, b) => b.score-a.score).map(function(player, index) {
                          return <span key={index}>
                            <span className={`player-name`}>{player.teamName && (player.teamName !== player.name) ? <span>{player.teamName} <small>({player.name})</small></span> : player.name}</span>
                            <span className={`player-score`}>{player.score}</span>
                          </span>;
                        })}
                      </h3>;
                    } else {
                      return <h3 key={index} className={`game-score`}>
                        <span className={`game-number`}>Game {index + 1}:</span>
                        <span className={`player-score`}>Not needed</span>
                      </h3>;
                    }
                  })
                }
                </section>
                <Link className={`button start-game rematch`} to={`/series/new?bestOf=${series.data().bestOf}&gameType=${series.data().gameType}&players=${series.data().players.map((player) => player.name)}&teams=${series.data().players.map((player) => player.teamName)}${game.pointsToWin ? `&pointsToWin=${game.pointsToWin}` : ''}`}>Rematch &raquo;</Link>
                <section className={`series-timestamps`}>
                {
                  !!series.data().startedAt ?
                    <h3>
                      <span className="game-start-label">Series Started:</span> <span className="game-start-time"><TimeAgo date={series.data().startedAt}/></span>
                    </h3> : null
                }
                {
                  !!series.data().endedAt ?
                    <h3>
                      <span className="game-start-label">Series Ended:</span> <span className="game-start-time"><TimeAgo date={series.data().endedAt}/></span>
                    </h3> : null
                }
                </section>
              </div>
              :
              <div>
                <h2 className={`series-score`}>
                  <span style={{marginRight:'1rem'}}>{series.data().gameType} series:</span>
                  {series.data().players.sort((a, b) => b.score-a.score).map(function(player, index) {
                    return <span key={index}>
                      <span className={`player-name`}>{player.name}</span>
                      <span className={`player-score`}>{player.score}</span>
                    </span>;
                  })}
                  <span className={`best-of`}>Game {gameNumber}, Best of {series.data().bestOf}</span>
                </h2>
                <section className={`active-game${game.pointsToWin ? '' : ' variable'}`}>
                  {
                    game.players.map(function (player, index) {
                      return <PlayerGamePoints key={index} pointsToWin={game.pointsToWin} onGoal={recordGoal.bind(this)} playerScore={player.score} player={player} winner={player.winner}/>;
                    })
                  }
                </section>
                <section>
                  {
                    !game.pointsToWin ?
                      <button className={`link end-game`} onClick={onClickEndGame}>End Game</button>
                      : null
                  }
                </section>
                <table className="goal-history">
                  <tbody>
                  {game.goals.reverse().map(function(goal, index){
                    const goalNumber = game.goals.length - index
                    return <tr key={ index }>
                      <td className="goal-number">
                        {goalNumber}
                      </td>
                      <td className="player-name">
                        {goal.player.name}
                      </td>
                      <td className="player-goal-number">
                        their {ordinalize(goal.player.score)}
                      </td>
                      <td className="goal-time">
                        <TimeAgo date={goal.scoredAt}/>
                      </td>
                      <td className="goal-action">
                        <a href="#" onClick={()=>{removeGoal(goal, goalNumber - 1)}}>remove</a>
                      </td>
                    </tr>;
                  })}
                  </tbody>
                </table>
                <section className={`game-timestamps`}>
                {
                  !!game.startedAt ?
                    <h3>
                      <span className="game-start-label">Game Started:</span> <span className="game-start-time"><TimeAgo date={game.startedAt}/></span>
                    </h3> : null
                }
                {
                  !!game.endedAt ?
                    <h3>
                      <span className="game-start-label">Game Ended:</span> <span className="game-start-time"><TimeAgo date={game.endedAt}/></span>
                    </h3> : null
                }
                </section>
              </div>
      }
    </div>
  );
}

export default PlaySeries;