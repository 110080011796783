import React from 'react';
import TimeAgo from "react-timeago/lib";

export default function SeriesSummary({ players, winner, bestOf, gameType, extended, startedAt, endedAt }) {
  const startedAtFormatted = startedAt ? new Date(startedAt).toLocaleString() : ''
  const endedAtFormatted = endedAt ? new Date(endedAt).toLocaleString() : ''
  return (
    <>
      <h1 className={`series-score`}>
        {players.sort((a, b) => b.score-a.score).map(function(player, index) {
          return <span key={index}>
            <span className={`player-name`}>{player.teamName && player.teamName !== player.name ? <span>{player.teamName} <small>({player.name})</small></span> : player.name}</span>
            <span className={`player-score`}>{player.score}</span>
          </span>;
        })}
      </h1>
      {
        winner ?
          <h2 className={`series-score`}>
            <span className={`best-of`}>{winner.name} wins the best of {bestOf} {gameType} series!</span>
          </h2>
        :
          <h2 className={`series-score`}>
            <em>In progress:</em> best of {bestOf} {gameType} series
          </h2>
      }
      {
        extended ?
          <span className={'extended'}>
            {
              startedAt ?
                <span>Started {startedAtFormatted}</span>
                : null
            }
            {
              endedAt ?
                <span>Ended {endedAtFormatted}</span>
                : null
            }
          </span>
        : null
      }
    </>
  )
}