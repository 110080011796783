import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from './useQuery';

import API from './api';
import { db } from './api';

function NewSeries(props) {

  const organization = props.organization;

  const query = useQuery();
  const startingBestOf = query.get('bestOf') ? parseInt(query.get('bestOf')) : null;
  const startingPointsToWin = query.get('pointsToWin') ? parseInt(query.get('pointsToWin')) : '';
  const startingPlayerNames = query.get('players') ? query.get('players').split(',') : null;
  const startingTeamNames = query.get('teams') ? query.get('teams').split(',') : [];
  const startingGameType = query.get('gameType') ? query.get('gameType') : null;

  const [allPlayers, setAllPlayers] = useState([]);
  const [gamePlayers, setGamePlayers] = useState([]);
  const [allPlayersAreLoading, setAllPlayersAreLoading] = useState(true);
  const [bestOf, setBestOf] = useState(startingBestOf);
  const [teamNames, setTeamNames] = useState(startingTeamNames)
  const [pointsToWin, setPointsToWin] = useState(startingPointsToWin)
  const [gameType, setGameType] = useState(startingGameType)

  const selectingPlayerNumber = gamePlayers.length + 1;

  let history = useHistory();

  function selectPlayer(player){
    if(gamePlayers.includes(player)) {
      alert(`${player.data().name} cannot play themselves`)
    } else {
      setGamePlayers([...gamePlayers, player]);
      setTeamNames([...teamNames, player.data().name]);
    }
  }

  function updateTeamName(index, name) {
    let names = [...teamNames]
    names[index] = name
    setTeamNames(names)
  }

  function selectBestOf(selection){
    setBestOf(selection);
  }

  function startGame(){
    API.startGame(organization, gamePlayers, bestOf, teamNames, pointsToWin, gameType)
    .then(function(seriesDocRef) {
      history.replace(`/series/${seriesDocRef.id}`);
    })
    .catch(function(error) {
      alert(`Error creating series: ${error}`);
    });
  }

  useEffect(() => {
    db.collection("organizations").doc(organization.id).collection("players")
      .onSnapshot(function(querySnapshot) {
        let players = [];
        querySnapshot.forEach(function(doc) {
          players.push(doc);
        });
        setAllPlayers(players);
        setAllPlayersAreLoading(false);
        if(!!startingPlayerNames) {
          let gamePlayers = players.filter(function(p) { return startingPlayerNames.includes(p.data().name); });
          setGamePlayers(gamePlayers);
        }
      });

    return function cleanup() {
      // close onSnapshot?
    };
  }, []);

  return (
    <div className="select-player col">
      {
        !!gameType ?
          <h3>{gameType} series</h3>
          :
          <div>
            <h3>Game type:</h3>
            {['Foosball', 'Chel'].map(function (name) {
              return <button key={name} className={`game-type${gameType === name ? ' selected' : ''}`} onClick={()=>setGameType(name)}>{name}</button>
            })}
          </div>
      }
      {
        !!bestOf ?
          <h3>Best of {bestOf}</h3>
          :
          <div>
            <h3>Best of:</h3>
            {[1,3,5,7].map(function (num) {
              return <button key={num} className={`best-of${bestOf === num ? ' selected' : ''}`} onClick={()=>selectBestOf(num)}>{num}</button>
            })}
          </div>
      }
      {
        allPlayersAreLoading ? <p>Loading player list...</p> :
          allPlayers.length > 0 ?
            <div>
              {
                gamePlayers.length === 0 ?
                  <h3>{`Select Player ${selectingPlayerNumber}:`}</h3>
                  :
                  <h3>{gamePlayers[0].data().name} vs.{!!gamePlayers[1] ? ` ${gamePlayers[1].data().name}` : ':'}</h3>
              }
              <ul>
                {gamePlayers.length < 2 ? allPlayers.map(function(player, index){
                  let playerData = player.data();
                  if(!gamePlayers.includes(player)) {
                    return <li key={ index }><button className={`link`} onClick={()=>selectPlayer(player)}>{playerData.name}</button></li>;
                  }
                }) : null}
              </ul>
              {
                gamePlayers.length > 1 && !!bestOf ?
                  <>
                    {
                      gamePlayers.map(function (player, index) {
                        return (
                          <div key={index}>
                            <h5>{gamePlayers[index].data().name}'s team name:</h5>
                            <input type={'text'} value={teamNames[index]} onChange={(event) => {updateTeamName(index, event.target.value)}}/>
                          </div>
                        )
                      })
                    }
                    <div>
                      <h5>Points to win:</h5>
                      <p>(optional, leave blank if game points are open-ended)</p>
                      <input type={'number'} value={pointsToWin} placeholder={'Number or blank'}  onChange={(event) => setPointsToWin(event.target.value)}/>
                    </div>
                    <button className={`link start-game`} onClick={startGame}>Start Game &raquo;</button>
                  </>
                  : null
              }
            </div>
          : <p>No players were found in your organization.</p>
      }
    </div>
  );
}

export default NewSeries;