import React from 'react';

function PlayerGameFixedPoints(props) {

  // Silk landscape: height: 422, width: 1024
  // Silk portrait: height: 846, width: 800
  // iMac: height: 851, width: 2560
  // iPhone X portrait: height: 678, width: 375
  // iPhone X landscape: height: 254, width: 724

  let widthMultiplier = 2;
  let heightInRems = 4;
  let scoreFontSizeInRems = 3;

  if(document.documentElement.clientWidth >= 800) {
    widthMultiplier = 3;
    heightInRems = 6;
    scoreFontSizeInRems = 5;
  }

  if(document.documentElement.clientWidth >= 1000) {
    widthMultiplier = 3.5;
    heightInRems = 8;
    scoreFontSizeInRems = 7;
  }

  return (
    <div className="points container">
      <div className="col">
        <div className="player-points-possible" onClick={()=>props.onGoal(props.player)} style={{width: (props.pointsToWin * widthMultiplier) + 'rem', height: heightInRems + 'rem'}}>
          <div className={"player-points" + (props.playerScore === 0 ? " no-points" : '')} style={{width: (props.playerScore * widthMultiplier) + 'rem', height: heightInRems + 'rem', fontSize: scoreFontSizeInRems + 'rem'}}>
            {props.playerScore}
          </div>
        </div>
      </div>
      <div className="col">
        <div className="player-name">
          <h2 onClick={()=>props.onGoal(props.player)} className={props.winner ? 'winner' : ''} style={{fontSize: scoreFontSizeInRems + 'rem'}}>{props.player.teamName && (props.player.teamName !== props.player.name) ? <span>{props.player.teamName} <small>({props.player.name})</small></span> : props.player.name}</h2>
        </div>
      </div>
    </div>
  );
}

export default PlayerGameFixedPoints;