import React from 'react';
import { Link } from "react-router-dom";

function Home() {
  return (
    <nav className="main-menu">
      <ul>
        <li>
          <h2><Link to={`/series/new`}>New Game +</Link></h2>
        </li>
        <li>
          <h2>
            <Link to={`/series`}>Game History</Link>
            <ul>
              <li>
                <Link to={`/series/type/Chel`}>Chel</Link>
              </li>
              <li>
                <Link to={`/series/type/Foosball`}>Foosball</Link>
              </li>
            </ul>
          </h2>
        </li>
        <li>
          <h2>
            <Link to={`/leaderboards`}>Leaderboards</Link>
            <ul>
              <li>
                <Link to={`/leaderboards/type/Chel`}>Chel</Link>
              </li>
              <li>
                <Link to={`/leaderboards/type/Foosball`}>Foosball</Link>
              </li>
            </ul>
          </h2>
        </li>
      </ul>
    </nav>
  );
}

export default Home;