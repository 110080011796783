import firebase from 'firebase';

export default {
  firebase: {
    apiKey: 'AIzaSyA8n891EEoOdDGcCKvJ6BK_MS4-ANF25IM',
    authDomain: 'foosboard-app.firebaseapp.com',
    projectId: 'foosboard-app',
  },
  firebaseUI: {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        return false; // avoid redirect
      }
    }
  }
}