import React, {useEffect, useState} from 'react'
import {db} from "./api";

export default function Leaderboards({ organization }) {
  // TODO centralize, maybe in api.js
  let seriesCollectionRef = db.collection("organizations").doc(organization.id).collection("series").orderBy("startedAt", "desc")

  const [loading, setLoading] = useState(true)
  const [seriesList, setSeriesList] = useState([])

  useEffect(() => {
    seriesCollectionRef.get().then(function(querySnapshot) {
      let series = []
      querySnapshot.forEach(function(doc) {
        series.push({...doc.data(), id: doc.id})
      });
      setSeriesList(series);
      setLoading(false);
    });

    return function cleanup() {
      // close onSnapshot?
    };
  }, []);

  if(loading) return <p>Loading...</p>;

  return (
    <section className={'leaderboards'}>

    </section>
  )
}