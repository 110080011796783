import React, { useEffect, useState } from 'react';
import { db } from "./api";
import { useParams, Link } from 'react-router-dom';
import SeriesSummary from "./SeriesSummary";

export default function ListSeries ({ organization }) {
  const { gameType } = useParams()
  let seriesCollectionRef = db.collection("organizations").doc(organization.id).collection("series").orderBy("startedAt", "desc")

  if(gameType) {
    seriesCollectionRef = seriesCollectionRef.where('gameType', '==', gameType)
  }

  const [loading, setLoading] = useState(true)
  const [seriesList, setSeriesList] = useState([])

  useEffect(() => {
    seriesCollectionRef.get().then(function(querySnapshot) {
      let series = []
      querySnapshot.forEach(function(doc) {
        series.push({...doc.data(), id: doc.id})
      });
      setSeriesList(series);
      setLoading(false);
    });

    return function cleanup() {
      // close onSnapshot?
    };
  }, []);

  if(loading) return <p>Loading...</p>;

  return (
    <section className={'list-series'}>
      <h2 className={'page-header'}>Series scores:</h2>
      <ul>
        {
          seriesList.map(function (series, index) {
            return <li key={index}>
              <Link to={`/series/${series.id}`}>
                <SeriesSummary players={series.players} winner={series.winner} bestOf={series.bestOf} gameType={series.gameType} extended={true} startedAt={series.startedAt} endedAt={series.endedAt} />
              </Link>
            </li>
          })
        }
      </ul>
    </section>
  )
}