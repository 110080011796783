import firebase from "firebase";
import config from './config';

firebase.initializeApp(config.firebase);
export const db = firebase.firestore(); // TODO use `static db` in the API class once nothing else calls this

export default class API {
  // static db = firebase.firestore();

  static startGame(organization, gamePlayers, bestOf, teamNames, pointsToWin, gameType){
    pointsToWin = pointsToWin === '' ? null : parseInt(pointsToWin);
    let players = gamePlayers.map(function (player, index) {
      let playerData = player.data();
      playerData.id = db.doc(`organizations/${organization.id}/players/${player.id}`);
      playerData.score = 0;
      playerData.winner = false;
      playerData.teamName = teamNames[index];
      return playerData;
    });
    return db.collection("organizations").doc(organization.id).collection("series").add({
      gameType: gameType,
      players: players,
      bestOf: bestOf,
      startedAt: Date.now(),
      endedAt: null,
      winner: null,
      games: new Array(bestOf).fill({ startedAt: null, endedAt: null, goals: [], players: players, winner: null, pointsToWin: pointsToWin }),
      currentGameIndex: 0,
    });
  }
}