import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { db } from './api';
import config from './config';
import firebase from 'firebase';

import Home from './Home';
import NewSeries from './NewSeries';
import PlaySeries from './PlaySeries';
import ListSeries from './ListSeries';
import Leaderboards from './Leaderboards'

function App() {

  const [signedIn, setSignedIn] = useState(false);
  const [organization, setOrganization] = useState(null);
  const unregisterAuthObserver = useRef(null);

  function handleAuthStateChanged(user) {
    if(!!user) {
      const email = user.email;
      let docRef = db.collection("users").doc(email);
      docRef.get().then(function(doc) {
        if (doc.exists) {
          if(!!!!doc.data().organizations && !!doc.data().organizations[0]) {
            setOrganization(doc.data().organizations[0]);
          }
          setSignedIn(true);
        } else {
          db.collection("users").doc(email).set({
            name: user.displayName,
            uid: user.uid,
          })
          .then(function() {
            console.log("Saved user data with email: ", email);
            setSignedIn(true);
          })
          .catch(function(error) {
            alert(`Error adding document: ${error}`);
            setSignedIn(false);
          });
        }
      }).catch(function(error) {
        console.log("Error getting document:", error);
        setSignedIn(false);
      });
    } else {
      setSignedIn(false);
    }
  }

  function logOut(){
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      setSignedIn(false);
      setOrganization(null);
    }).catch(function(error) {
      alert(`Error signing out: ${error}`);
    });
  }

  useEffect(() => {
    if (/\bSilk\b/.test(navigator.userAgent)) {
      document.body.className = 'browser-silk';
    }

    unregisterAuthObserver.current = firebase.auth().onAuthStateChanged(
      (user) => handleAuthStateChanged(user)
    );

    return function cleanup() {
      unregisterAuthObserver();
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <nav>
          <h1 className="logo">
            <Link to="/" className={`navbar-brand`}>foosboard</Link>
            {
              process.env.NODE_ENV === 'development' ?
                <em className={`dev-environment-alert${organization && organization.id === 'PQ' ? ' dev-environment-danger' : ''}`}>dev: {organization ? organization.id : 'no'} org</em> : null
            }
          </h1>
        </nav>
        <section id="main-content-wrapper">
          {
            signedIn ?
              <div>
              {
                !!organization ?
                  <Switch>
                    <Route path="/series/type/:gameType">
                      <ListSeries organization={organization} />
                    </Route>
                    <Route path="/series/new">
                      <NewSeries organization={organization} />
                    </Route>
                    <Route path="/series/:seriesId/game/:gameNumberParam">
                      <PlaySeries organization={organization} />
                    </Route>
                    <Route path="/series/:seriesId">
                      <PlaySeries organization={organization} />
                    </Route>
                    <Route path="/series">
                      <ListSeries organization={organization} />
                    </Route>
                    <Route path="/leaderboards">
                      <Leaderboards organization={organization} />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                : <p>This user does not have access to an organization. Please contact an administrator to continue or try signing out and back in again.</p>
              }
              </div>
              :
              <StyledFirebaseAuth uiConfig={config.firebaseUI} firebaseAuth={firebase.auth()} />
          }
        </section>
        {
          signedIn ?
            <p>
              <button onClick={logOut} className={`link`}>Sign out</button>
            </p>
          : null }
      </div>
    </Router>
  );
}

export default App;
