import React from 'react';

function PlayerGameVariablePoints(props) {
  return (
    <div className="scoreboard">
      <div className={"player-points"} onClick={()=>props.onGoal(props.player)}>
        {props.playerScore}
      </div>
      <div className="player-team">
        <h2 onClick={()=>props.onGoal(props.player)} className={props.winner ? 'winner' : ''}>{props.player.teamName}</h2>
      </div>
      {
        props.player.name !== props.player.teamName ?
          <div className="player-name">
            <h4 onClick={()=>props.onGoal(props.player)} className={props.winner ? 'winner' : ''}>({props.player.name})</h4>
          </div>
        : null
      }
    </div>
  );
}

export default PlayerGameVariablePoints;